import locationActiveIcon from "assets/icons/map/loc-active.png";
import locationNormalIcon from "assets/icons/map/loc-normal.png";
import locationHoverIcon from "assets/icons/map/loc-hover.png";

import partnerNormalIcon from "assets/icons/map/par-normal.png";
import partnerHoverIcon from "assets/icons/map/par-hover.png";
import partnerActiveIcon from "assets/icons/map/par-active.png";

// Service icons
import serviceDefaultIcon from "assets/icons/map/wt-1-0-0.png";
import serviceDefaultHoverIcon from "assets/icons/map/wt-1-0-1.png";
import serviceDefaultActiveIcon from "assets/icons/map/wt-1-0-2.png";

import serviceNotstartedIcon from "assets/icons/map/wt-1-1-0.png";
import serviceNotstartedHoverIcon from "assets/icons/map/wt-1-1-1.png";
import serviceNotstartedActiveIcon from "assets/icons/map/wt-1-1-2.png";

import serviceInprogressIcon from "assets/icons/map/wt-1-2-0.png";
import serviceInprogressHoverIcon from "assets/icons/map/wt-1-2-1.png";
import serviceInprogressActiveIcon from "assets/icons/map/wt-1-2-2.png";

import serviceOverdueIcon from "assets/icons/map/wt-1-3-0.png";
import serviceOverdueHoverIcon from "assets/icons/map/wt-1-3-1.png";
import serviceOverdueActiveIcon from "assets/icons/map/wt-1-3-2.png";

import serviceNoncompliantIcon from "assets/icons/map/wt-1-4-0.png";
import serviceNoncompliantHoverIcon from "assets/icons/map/wt-1-4-1.png";
import serviceNoncompliantActiveIcon from "assets/icons/map/wt-1-4-2.png";

import serviceOntimeIcon from "assets/icons/map/wt-1-5-0.png";
import serviceOntimeHoverIcon from "assets/icons/map/wt-1-5-1.png";
import serviceOntimeActiveIcon from "assets/icons/map/wt-1-5-2.png";

import serviceOntimeonsiteIcon from "assets/icons/map/wt-1-6-0.png";
import serviceOntimeonsiteHoverIcon from "assets/icons/map/wt-1-6-1.png";
import serviceOntimeonsiteActiveIcon from "assets/icons/map/wt-1-6-2.png";

import serviceFullycompliantIcon from "assets/icons/map/wt-1-7-0.png";
import serviceFullycompliantHoverIcon from "assets/icons/map/wt-1-7-1.png";
import serviceFullycompliantActiveIcon from "assets/icons/map/wt-1-7-2.png";

// Inspection icons
import inspectionDefaultIcon from "assets/icons/map/wt-2-0-0.png";
import inspectionDefaultHoverIcon from "assets/icons/map/wt-2-0-1.png";
import inspectionDefaultActiveIcon from "assets/icons/map/wt-2-0-2.png";

import inspectionNotstartedIcon from "assets/icons/map/wt-2-1-0.png";
import inspectionNotstartedHoverIcon from "assets/icons/map/wt-2-1-1.png";
import inspectionNotstartedActiveIcon from "assets/icons/map/wt-2-1-2.png";

import inspectionInprogressIcon from "assets/icons/map/wt-2-2-0.png";
import inspectionInprogressHoverIcon from "assets/icons/map/wt-2-2-1.png";
import inspectionInprogressActiveIcon from "assets/icons/map/wt-2-2-2.png";

import inspectionOverdueIcon from "assets/icons/map/wt-2-3-0.png";
import inspectionOverdueHoverIcon from "assets/icons/map/wt-2-3-1.png";
import inspectionOverdueActiveIcon from "assets/icons/map/wt-2-3-2.png";

import inspectionNoncompliantIcon from "assets/icons/map/wt-2-4-0.png";
import inspectionNoncompliantHoverIcon from "assets/icons/map/wt-2-4-1.png";
import inspectionNoncompliantActiveIcon from "assets/icons/map/wt-2-4-2.png";

import inspectionOntimeIcon from "assets/icons/map/wt-2-5-0.png";
import inspectionOntimeHoverIcon from "assets/icons/map/wt-2-5-1.png";
import inspectionOntimeActiveIcon from "assets/icons/map/wt-2-5-2.png";

import inspectionOntimeonsiteIcon from "assets/icons/map/wt-2-6-0.png";
import inspectionOntimeonsiteHoverIcon from "assets/icons/map/wt-2-6-1.png";
import inspectionOntimeonsiteActiveIcon from "assets/icons/map/wt-2-6-2.png";

import inspectionFullycompliantIcon from "assets/icons/map/wt-2-7-0.png";
import inspectionFullycompliantHoverIcon from "assets/icons/map/wt-2-7-1.png";
import inspectionFullycompliantActiveIcon from "assets/icons/map/wt-2-7-2.png";

export const mapIcons = {
  location: {
    active: locationActiveIcon,
    normal: locationNormalIcon,
    hover: locationHoverIcon,
  },
  partner: {
    normal: partnerNormalIcon,
    hover: partnerHoverIcon,
    active: partnerActiveIcon,
  },
  1: {
    0: {
      0: serviceDefaultIcon,
      1: serviceDefaultHoverIcon,
      2: serviceDefaultActiveIcon,
    },
    1: {
      0: serviceNotstartedIcon,
      1: serviceNotstartedHoverIcon,
      2: serviceNotstartedActiveIcon,
    },
    2: {
      0: serviceInprogressIcon,
      1: serviceInprogressHoverIcon,
      2: serviceInprogressActiveIcon,
    },
    3: {
      0: serviceOverdueIcon,
      1: serviceOverdueHoverIcon,
      2: serviceOverdueActiveIcon,
    },
    4: {
      0: serviceNoncompliantIcon,
      1: serviceNoncompliantHoverIcon,
      2: serviceNoncompliantActiveIcon,
    },
    5: {
      0: serviceOntimeIcon,
      1: serviceOntimeHoverIcon,
      2: serviceOntimeActiveIcon,
    },
    6: {
      0: serviceOntimeonsiteIcon,
      1: serviceOntimeonsiteHoverIcon,
      2: serviceOntimeonsiteActiveIcon,
    },
    7: {
      0: serviceFullycompliantIcon,
      1: serviceFullycompliantHoverIcon,
      2: serviceFullycompliantActiveIcon,
    },
    8: {
      0: serviceDefaultIcon,
      1: serviceDefaultHoverIcon,
      2: serviceDefaultActiveIcon,
    },
  },
  2: {
    0: {
      0: inspectionDefaultIcon,
      1: inspectionDefaultHoverIcon,
      2: inspectionDefaultActiveIcon,
    },
    1: {
      0: inspectionNotstartedIcon,
      1: inspectionNotstartedHoverIcon,
      2: inspectionNotstartedActiveIcon,
    },
    2: {
      0: inspectionInprogressIcon,
      1: inspectionInprogressHoverIcon,
      2: inspectionInprogressActiveIcon,
    },
    3: {
      0: inspectionOverdueIcon,
      1: inspectionOverdueHoverIcon,
      2: inspectionOverdueActiveIcon,
    },
    4: {
      0: inspectionNoncompliantIcon,
      1: inspectionNoncompliantHoverIcon,
      2: inspectionNoncompliantActiveIcon,
    },
    5: {
      0: inspectionOntimeIcon,
      1: inspectionOntimeHoverIcon,
      2: inspectionOntimeActiveIcon,
    },
    6: {
      0: inspectionOntimeonsiteIcon,
      1: inspectionOntimeonsiteHoverIcon,
      2: inspectionOntimeonsiteActiveIcon,
    },
    7: {
      0: inspectionFullycompliantIcon,
      1: inspectionFullycompliantHoverIcon,
      2: inspectionFullycompliantActiveIcon,
    },
    8: {
      0: inspectionDefaultIcon,
      1: inspectionDefaultHoverIcon,
      2: inspectionDefaultActiveIcon,
    },
  },
};
