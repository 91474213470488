import { useQuery } from "react-query";
import http from "services/httpService";
import { apiUrl } from "lib/config";
import { useParams } from "react-router-dom";
import moment from "moment";

export const useFetchLocationMetrics = (queryString) => {
  const { locationId } = useParams();

  return useQuery({
    queryKey: ["locationMetrics", locationId, queryString],
    queryFn: () =>
      http.get(`${apiUrl}/locations/${locationId}/metrics${queryString}`),
    enabled: !!locationId,
    initialData: {
      data: {
        data: {
          metrics: {},
        },
      },
    },
    select: (data) => {
      return {
        metrics: data?.data?.data?.metrics,
        metaData: {
          start_date: moment(data?.data?.data?.meta_data?.start_date).format(
            "YYYY-MM-DD"
          ),
          end_date: moment(data?.data?.data?.meta_data?.end_date).format(
            "YYYY-MM-DD"
          ),
        },
      };
    },
  });
};
