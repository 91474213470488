import { useState } from "react";
import { Box, Card, CardActionArea, Typography } from "@material-ui/core";
import { makeStyles, alpha } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { mapIcons } from "./mapIcons";

const statusOptions = [
  {
    id: 1,
    label: "Not Started",
    category: "active",
    iconKey: "1", // Service type
    statusKey: "1", // Not Started status
  },
  {
    id: 2,
    label: "In Progress",
    category: "active",
    iconKey: "1",
    statusKey: "2", // In Progress status
  },
  {
    id: 3,
    label: "Overdue",
    category: "active",
    iconKey: "1",
    statusKey: "3", // Overdue status
  },
  {
    id: 5,
    label: "On Time",
    category: "verified",
    iconKey: "1",
    statusKey: "5", // On Time status
  },
  {
    id: 6,
    label: "On Time and On Site",
    category: "verified",
    iconKey: "1",
    statusKey: "6", // On Time On Site status
  },
  {
    id: 7,
    label: "Fully Compliant",
    category: "verified",
    iconKey: "1",
    statusKey: "7", // Fully Compliant status
  },
  {
    id: 8,
    label: "Completed",
    category: "active",
    iconKey: "1",
    statusKey: "0", // Completed status
  },
  {
    id: 4,
    label: "Not Compliant",
    category: "verified",
    iconKey: "1",
    statusKey: "4", // Non Compliant status
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: 400,
    backgroundColor: "#ffffff",
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.primary,
    // paddingLeft: 8,
  },
  categoryTitle: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 600,
    // paddingLeft: 8,
  },
  selectedSection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  selectedHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  selectedHeaderText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  clearAllButton: {
    color: "#ffffff",
    backgroundColor: theme.palette.secondary.main,
    textTransform: "none",
    padding: theme.spacing(0, 1.5),
    minWidth: "auto",
    borderRadius: 4,
    fontSize: 13,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  statusContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(0.75),
  },
  card: {
    backgroundColor: "#ffffff",
    borderRadius: 6,
    boxShadow: "none",
    border: `1px solid ${theme.palette.divider}`,
    "&.selected": {
      border: `1px solid #4F98BC`,
    },
  },
  cardAction: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    width: "auto",
    height: 32,
  },
  iconContainer: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    marginRight: theme.spacing(0.75),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
  },
  statusName: {
    fontSize: 13,
    color: theme.palette.text.primary,
    textAlign: "left",
    lineHeight: 1.2,
    marginLeft: theme.spacing(0.5),
  },
  closeIcon: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: 16,
    marginLeft: theme.spacing(0.5),
    cursor: "pointer",
  },
  divider: {
    borderTop: "solid 1px #ececec",
    margin: theme.spacing(2, 0),
  },
  statusIcon: {
    width: 20,
    height: 20,
    objectFit: "contain",
  },
}));

const StatusFilter = ({ selectedStatuses = [], onStatusSelect }) => {
  const classes = useStyles();

  const handleStatusToggle = (status) => {
    const isSelected = selectedStatuses.some((s) => s.id === status.id);
    if (isSelected) {
      onStatusSelect(selectedStatuses.filter((s) => s.id !== status.id));
    } else {
      onStatusSelect([...selectedStatuses, status]);
    }
  };

  const handleClearAll = () => {
    onStatusSelect([]);
  };

  const getStatusIcon = (status, isHovered = false) => {
    const stateKey = isHovered ? "1" : "0"; // Use hover state (1) or normal state (0)
    return mapIcons[status.iconKey][status.statusKey][stateKey];
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        Filter by Workticket Status
      </Typography>

      {selectedStatuses.length > 0 && (
        <Box className={classes.selectedSection}>
          <Box className={classes.selectedHeader}>
            <Typography className={classes.selectedHeaderText}>
              Selected
            </Typography>
            <Button
              className={classes.clearAllButton}
              onClick={handleClearAll}
              size="small"
            >
              Clear All
            </Button>
          </Box>
          <Box className={classes.statusContainer}>
            {selectedStatuses.map((status) => (
              <Card key={status.id} className={`${classes.card} selected`}>
                <CardActionArea className={classes.cardAction}>
                  <img
                    src={getStatusIcon(status)}
                    alt={status.label}
                    className={classes.statusIcon}
                  />
                  <Typography className={classes.statusName}>
                    {status.label}
                  </Typography>
                  <CloseIcon
                    className={classes.closeIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleStatusToggle(status);
                    }}
                  />
                </CardActionArea>
              </Card>
            ))}
          </Box>
          <div className={classes.divider} />
        </Box>
      )}

      <Typography className={classes.categoryTitle}>
        Active Worktickets
      </Typography>
      <Box className={classes.statusContainer}>
        {statusOptions
          .filter(
            (status) =>
              status.category === "active" &&
              !selectedStatuses.some((s) => s.id === status.id)
          )
          .map((status) => (
            <Card key={status.id} className={classes.card}>
              <CardActionArea
                className={classes.cardAction}
                onClick={() => handleStatusToggle(status)}
              >
                <img
                  src={getStatusIcon(status)}
                  alt={status.label}
                  className={classes.statusIcon}
                />
                <Typography className={classes.statusName}>
                  {status.label}
                </Typography>
              </CardActionArea>
            </Card>
          ))}
      </Box>

      <div className={classes.divider} />

      <Typography className={classes.categoryTitle}>
        Verified Worktickets
      </Typography>
      <Box className={classes.statusContainer}>
        {statusOptions
          .filter(
            (status) =>
              status.category === "verified" &&
              !selectedStatuses.some((s) => s.id === status.id)
          )
          .map((status) => (
            <Card key={status.id} className={classes.card}>
              <CardActionArea
                className={classes.cardAction}
                onClick={() => handleStatusToggle(status)}
              >
                <img
                  src={getStatusIcon(status)}
                  alt={status.label}
                  className={classes.statusIcon}
                />
                <Typography className={classes.statusName}>
                  {status.label}
                </Typography>
              </CardActionArea>
            </Card>
          ))}
      </Box>
    </Box>
  );
};

export default StatusFilter;
