export const getUserType = (u) => {
  if (u?.tier_classification === "client") return "customer";
  if (u?.tier_classification === "subcontractor") return "partner";
  return "internal";
};

export const initialVisibleLayersByUser = (u) => {
  const userType = getUserType(u);
  switch (userType) {
    case "internal":
      return ["LOCATIONS", "WORKTICKETS", "PARTNERS"];
    case "customer":
      return ["LOCATIONS", "WORKTICKETS"];
    case "partner":
      return ["WORKTICKETS"];
    default:
      return ["WORKTICKETS"];
  }
};
