import React, { createContext, useReducer, useContext } from "react";

export const MapContext = createContext();
export const MapDispatchContext = createContext();
const defaultFilterKeys = {
  assigned: [],
  city: [],
  customer: [],
  director: [],
  insights_reports: [],
  job: [],
  level_compliance: [],
  manager: [],
  completion_method: [],
  project: [],
  region: [],
  service_category: [],
  sites: [],
  state: [],
  trades: [],
  workticket_types: [],
  workticket_status: [],
  zip_code: [],
};

const defaultMapState = {
  mapFilters: defaultFilterKeys,
  mapDates: {},
  sessionId: null,
};

function formatDate(date) {
  if (!(date instanceof Date)) return date;
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function buildQueryString(filters) {
  if (Object.keys(filters).length === 0) return "";

  const queryParts = [];

  Object.entries(filters).forEach(([key, values]) => {
    if (Array.isArray(values) && values.length > 0) {
      values.forEach((value) => {
        queryParts.push(`filters[${key}][]=${value.id || value}`);
      });
    }
  });

  const queryString = queryParts.length > 0 ? `&${queryParts.join("&")}` : "";
  return queryString;
}

function mapReducer(state, action) {
  let newState;

  switch (action.type) {
    case "SET_MAP_FILTERS": {
      newState = {
        ...state,
        mapFilters: {
          ...defaultFilterKeys,
          ...state.mapFilters,
          ...action.filters,
        },
        sessionId: null,
      };
      localStorage.setItem("mapFilters", JSON.stringify(newState.mapFilters));
      return newState;
    }
    case "SET_MAP_DATES": {
      newState = {
        ...state,
        mapDates: {
          start_date: formatDate(action.startDate),
          end_date: formatDate(action.endDate),
        },
        sessionId: null,
      };
      localStorage.setItem("mapDates", JSON.stringify(newState.mapDates));
      return newState;
    }
    case "SET_SESSION_ID": {
      return {
        ...state,
        sessionId: action.sessionId,
      };
    }
    case "CLEAR_FILTERS": {
      newState = {
        ...state,
        mapFilters: defaultFilterKeys,
        mapDates: {},
        sessionId: null,
      };
      localStorage.removeItem("mapFilters");
      localStorage.removeItem("mapDates");
      return newState;
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

const getInitialState = () => {
  const savedFilters = localStorage.getItem("mapFilters");
  const savedDates = localStorage.getItem("mapDates");

  return {
    mapFilters: savedFilters ? JSON.parse(savedFilters) : defaultFilterKeys,
    mapDates: savedDates ? JSON.parse(savedDates) : {},
  };
};

export function MapProvider({ children }) {
  const [state, dispatch] = useReducer(mapReducer, getInitialState());

  return (
    <MapContext.Provider value={state}>
      <MapDispatchContext.Provider value={dispatch}>
        {children}
      </MapDispatchContext.Provider>
    </MapContext.Provider>
  );
}

export const useMapContext = () => useContext(MapContext);
export const useMapDispatch = () => {
  const context = useContext(MapDispatchContext);
  if (context === undefined) {
    throw new Error("useMapDispatch must be used within a MapProvider");
  }
  return context;
};
