import { useContext, useState, useRef, useEffect } from "react";
import { Box, Typography, makeStyles, Button } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import {
  MapIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/24/outline";
import { Trades } from "components/ui/Trades";
import { EditLocationDetailsModal } from "pages/locationDetails/components";
import { useLocationDetailsContext } from "pages/locationDetails/context";
import { permissionDashboard, hasPermission } from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import locationIcon from "assets/icons/map/loc-active.png";

mapboxgl.accessToken =
  "pk.eyJ1Ijoiam9lbGJyZXdlcjAxIiwiYSI6ImNtMmIwdTdjbjAwaG8ya3B0MHI5aW0zcmkifQ.Ms84oNVTGNwi-B4vF-9zOQ";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    gap: 38,
    padding: "24px 16px",
    [theme.breakpoints.up("md")]: {
      padding: 0,
      minHeight: 200,
    },
  },
  editButton: {
    fontSize: 14,
    lineHeight: "20px",
    textTransform: "none",
    padding: "6px 16px",
    minWidth: 0,
    minHeight: 0,
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    [theme.breakpoints.up("md")]: {
      gap: 12,
    },
  },
  infoSection: {
    display: "flex",
    gap: 8,
  },
  infoSectionColumn: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  infoSectionsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    [theme.breakpoints.up("md")]: {
      gap: 24,
    },
  },
  infoSectionLabel: {
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  locationAddress: {
    fontSize: 14,
  },
  locationName: {
    fontSize: 18,
    fontWeight: 700,
    color: "#4D4D4D",
  },
  mapContainer: {
    maxHeight: 200,
    minWidth: "45%",
    borderRadius: 4,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  nameAndEditContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    gap: "clamp(20px, 5vw, 80px)",
  },
  icon: {
    width: 20,
    height: 20,
    flexShrink: 0,
    color: "#D9D9D9",
  },
}));

export const LocationInformation = () => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [editLocationModalOpen, setEditLocationModalOpen] = useState(false);
  const {
    details: { data, isLoading },
  } = useLocationDetailsContext();

  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (isLoading || !data || !data.latitude || !data.longitude || map.current)
      return;

    const coordinates = [parseFloat(data.longitude), parseFloat(data.latitude)];

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: coordinates,
      zoom: 15,
      interactive: false,
    });

    const el = document.createElement("div");
    const img = document.createElement("img");
    img.src = locationIcon;
    img.style.width = "36px";
    img.style.height = "36px";
    el.appendChild(img);

    new mapboxgl.Marker({
      element: el,
      anchor: "bottom",
    })
      .setLngLat(coordinates)
      .addTo(map.current);

    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, [data, isLoading]);

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.infoContainer}>
          <Box className={classes.nameAndEditContainer}>
            {isLoading ? (
              <Skeleton variant="text" width={200} height={28} />
            ) : (
              <Typography className={classes.locationName}>
                {data.name}
              </Typography>
            )}
            {hasPermission(
              permissionDashboard.LOCATION_MANAGE,
              permissions
            ) && (
              <Button
                variant="outlined"
                className={classes.editButton}
                disableElevation
                onClick={
                  isLoading ? undefined : () => setEditLocationModalOpen(true)
                }
                disabled={isLoading}
              >
                Edit
              </Button>
            )}
          </Box>
          <Box className={classes.infoSectionsContainer}>
            <Box className={classes.infoSection}>
              <MapIcon className={classes.icon} />
              <Box className={classes.infoSectionColumn}>
                <Typography className={classes.infoSectionLabel}>
                  Address
                </Typography>
                {isLoading ? (
                  <Skeleton variant="text" width={250} height={20} />
                ) : (
                  <Typography className={classes.locationAddress}>
                    {`${data.address}, ${data.city}`}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box className={classes.infoSection}>
              <ClipboardDocumentCheckIcon className={classes.icon} />
              <Box className={classes.infoSectionColumn}>
                <Typography className={classes.infoSectionLabel}>
                  Trades Provided
                </Typography>
                {isLoading ? (
                  <Skeleton variant="text" width={300} height={24} />
                ) : (
                  <Trades trades={data?.trades || []} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box ref={mapContainer} className={classes.mapContainer} />
      </Box>
      <EditLocationDetailsModal
        editLocationModalOpen={editLocationModalOpen}
        setEditLocationModalOpen={setEditLocationModalOpen}
      />
    </>
  );
};
