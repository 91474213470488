import { useContext, useEffect, useState } from "react";
import {
  Box,
  makeStyles,
  Button,
  IconButton,
  Input,
  useTheme,
} from "@material-ui/core";
import { SendRounded } from "@material-ui/icons";
import WorkticketComment from "components/ui/Worktickets/WorkticketComment";
import { ZeroState, PaginationBar } from "pages/locationDetails/components";
import {
  useLocationDetailsContext,
  useLocationDetailsDispatch,
} from "pages/locationDetails/context";
import {
  useCreateLocationComment,
  useUpdateLocationComment,
  useDeleteLocationComment,
} from "pages/locationDetails/api";
import classNames from "classnames";
import { CommentsNone } from "assets/illustrations";
import { Skeleton } from "@material-ui/lab";
import GlobalUiContext from "contexts/globalUiContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    padding: 0,
    backgroundColor: theme.palette.background.default,
    width: "100%",
    minWidth: 0,
    [theme.breakpoints.up("md")]: {
      padding: "24px 16px",
    },
  },
  button: {
    textTransform: "none",
    fontSize: "14px",
    lineHeight: "16.8px",
    padding: "8px 16px",
    "&.selected": {
      backgroundColor: theme.palette.secondary.main,
      color: "#FFFFFF",
    },
    "&:not(.selected)": {
      backgroundColor: "#EDF5F8",
      color: theme.palette.secondary.main,
    },
  },
  buttonContainer: {
    display: "flex",
    gap: 12,
  },
  commentCard: {
    margin: 0,
  },
  commentCardUser: {
    backgroundColor: "#EDF5F8",
  },
  commentAndInputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    [theme.breakpoints.up("md")]: {
      flexDirection: "column-reverse",
    },
  },
  commentCardsMobileContainerZeroState: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  inputContainer: {
    position: "relative",
  },
  input: {
    border: "1px solid #ECECEC",
    borderRadius: 4,
    fontSize: 14,
    lineHeight: "20px",
    padding: 10,
    paddingRight: 48,
  },
  inputSending: {
    color: "#D9D9D9",
  },
  sendButton: {
    position: "absolute",
    right: 6,
    top: 6,
    padding: 0,
    width: 28,
    height: 28,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sendArrowIcon: {
    width: 20,
    height: 20,
    flexShrink: 0,
  },
  commentCardsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  zeroState: {
    margin: "3rem 0 2rem",
  },
}));

const CommentsSkeleton = () => {
  const classes = useStyles();
  return (
    <Box className={classes.commentCardsContainer}>
      {Array(6)
        .fill(null)
        .map((_, index) => (
          <Skeleton
            key={index}
            variant="rect"
            height={100}
            style={{
              borderRadius: 8,
              animationDelay: `${index * 30}ms`,
            }}
          />
        ))}
    </Box>
  );
};

export const Comments = () => {
  const theme = useTheme();
  const classes = useStyles();
  const {
    comments: {
      data: comments,
      isLoading: isLoadingComments,
      isFetching: isFetchingComments,
    },
    setActiveCommentsTab,
  } = useLocationDetailsContext();
  const { mutate: createComment, isLoading } = useCreateLocationComment();
  const { mutate: updateComment } = useUpdateLocationComment();
  const { mutate: deleteComment } = useDeleteLocationComment();
  const [activeTab, setActiveTab] = useState(null);
  const [newComment, setNewComment] = useState("");
  const [commentTabs, setCommentTabs] = useState([]);
  const { globalUi } = useContext(GlobalUiContext);
  const user = localStorage.getItem("user");
  const userData = JSON.parse(user);
  const surfacedComments = comments?.[activeTab]?.cardData;
  const pagination = comments?.[activeTab]?.pagination;
  const dispatch = useLocationDetailsDispatch();

  useEffect(() => {
    if (comments) {
      if (globalUi.role.category === "client") {
        setCommentTabs(["customer"]);
        setActiveTab("customer");
      } else if (globalUi.role.category === "subcontractor") {
        setCommentTabs(["subcontractor"]);
        setActiveTab("subcontractor");
      } else {
        setCommentTabs(Object.keys(comments));
        setActiveTab(Object.keys(comments)[0]);
      }
    }
  }, [comments, globalUi]);

  const handleCreateComment = () => {
    createComment(
      {
        tab: activeTab,
        body: newComment,
      },
      {
        onSuccess: () => {
          setNewComment("");
        },
      }
    );
  };

  const handlePageChange = (newPage) => {
    dispatch({
      type: "SET_COMMENTS_PAGE",
      page: newPage,
      tab: activeTab,
    });
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setActiveCommentsTab(tab);
    if (pagination) {
      handlePageChange(pagination.currentPage);
    }
  };

  return (
    <Box className={classes.root}>
      {commentTabs.length > 1 && (
        <Box className={classes.buttonContainer}>
          {commentTabs.map((key) => (
            <Button
              key={key}
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => handleTabChange(key)}
              className={classNames(classes.button, {
                selected: activeTab === key,
              })}
            >
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </Button>
          ))}
        </Box>
      )}
      <Box
        className={classNames(classes.commentAndInputContainer, {
          [classes.commentCardsMobileContainerZeroState]:
            !activeTab &&
            !surfacedComments &&
            !isLoadingComments &&
            !isFetchingComments,
        })}
      >
        <Box className={classes.inputContainer}>
          <Input
            className={classNames(classes.input, {
              [classes.inputSending]: isLoading,
            })}
            placeholder="Add a comment"
            fullWidth
            multiline
            disableUnderline
            onChange={({ target }) => setNewComment(target.value)}
            value={newComment}
          />
          <IconButton
            className={classes.sendButton}
            onClick={handleCreateComment}
            disabled={!newComment}
          >
            <SendRounded
              className={classes.sendArrowIcon}
              style={{
                color:
                  !!newComment && !isLoading
                    ? theme.palette.secondary.main
                    : "#D9D9D9",
              }}
            />
          </IconButton>
        </Box>
        {isLoadingComments || isFetchingComments ? (
          <PaginationBar
            data={surfacedComments || []}
            pagination={
              pagination || {
                currentPage: 1,
                itemsPerPage: 6,
                totalItems: 6,
                totalPages: 1,
              }
            }
            onPageChange={handlePageChange}
          >
            {() => <CommentsSkeleton />}
          </PaginationBar>
        ) : surfacedComments?.length > 0 ? (
          <PaginationBar
            data={surfacedComments}
            pagination={pagination}
            onPageChange={handlePageChange}
          >
            {(currentItems) => (
              <Box className={classes.commentCardsContainer}>
                {currentItems.map((comment) => (
                  <WorkticketComment
                    key={comment.id}
                    id={comment.id}
                    className={[
                      classes.commentCard,
                      comment.user_id.toString() === userData.id.toString() &&
                        classes.commentCardUser,
                    ]}
                    externalId={comment.external_id}
                    user={{
                      id: comment.user_id,
                      name: comment.user_name,
                      avatar: comment.profile_url,
                    }}
                    time={comment.video_metadata}
                    date={comment.created_at}
                    message={comment.body}
                    fileId={comment.file_id}
                    fileUrl={comment.file_url}
                    fileName={comment.display_name}
                    fileOwner={comment.user_name}
                    fileType={comment.mime_type}
                    fileSize={comment.file_size}
                    fileCreateAt={comment.file_created_at}
                    workticketId={comment.workticket_id}
                    moduleType={comment.module_type}
                    displayFileDetails={true}
                    showMenuOptions={userData.id === Number(comment.user_id)}
                    onUpdateComment={(commentId, commentBody) =>
                      updateComment({
                        commentId,
                        body: {
                          tab: activeTab,
                          body: commentBody,
                        },
                      })
                    }
                    onDeleteComment={deleteComment}
                  />
                ))}
              </Box>
            )}
          </PaginationBar>
        ) : (
          <ZeroState
            className={classes.zeroState}
            illustration={<CommentsNone height={48} />}
            text="No comments yet"
            subText="Be the first to comment on this location"
          />
        )}
      </Box>
    </Box>
  );
};
