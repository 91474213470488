export const MAP_CONFIG = {
  LAYER_TYPES: {
    LOCATIONS: "LOCATIONS",
    WORKTICKETS: "WORKTICKETS",
    PARTNERS: "PARTNERS",
    BUILDINGS: "BUILDINGS",
  },

  ICONS: {
    WORKTICKET: {
      1: "service",
      2: "inspection",
    },
    STATES: {
      DEFAULT: { id: 0, suffix: "" },
      HOVER: { id: 1, suffix: "-hover" },
      ACTIVE: { id: 2, suffix: "-active" },
    },
  },

  WORKTICKET_STATUSES: {
    DEFAULT: { id: 0, name: "default" },
    NOT_STARTED: { id: 1, name: "notstarted" },
    IN_PROGRESS: { id: 2, name: "inprogress" },
    OVERDUE: { id: 3, name: "overdue" },
    NON_COMPLIANT: { id: 4, name: "noncompliant" },
    ON_TIME: { id: 5, name: "ontime" },
    ON_TIME_ONSITE: { id: 6, name: "ontimeonsite" },
    FULLY_COMPLIANT: { id: 7, name: "fullycompliant" },
  },

  MAP: {
    MAX_PITCH_DEGREES: 60,
    DEFAULT_CENTER: [-98.5795, 39.8283], // Center of the US
    DEFAULT_ZOOM: 4,
    STYLE_URL: "mapbox://styles/mapbox/streets-v11",
  },

  COORDINATES: {
    METERS_PER_DEGREE: 111319.9,
    DESIRED_SPREAD_METERS: 12,
    MAX_SPREAD_MULTIPLIER: 5,
  },
};
