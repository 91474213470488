export const formatDate = (date) => {
  const localDate = new Date(date + "T00:00:00"); // Ensure the date is treated as local
  return localDate.toLocaleDateString();
};

export const formatStringAllCapsWithUnderscores = (string) =>
  string?.toUpperCase().replace(/\s+/g, "_");
export const formatStringReadable = (string) =>
  string
    ?.toLowerCase()
    .replace(/_/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
