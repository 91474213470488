import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  IconButton,
  Avatar,
  Portal,
  Snackbar,
} from "@material-ui/core";
import { XMarkIcon } from "@heroicons/react/20/solid";
import WorkticketStatusChip from "components/ui/Worktickets/WorkticketStatusChip";
import mapIcon from "assets/icons/map-icon.png";
import CommentsIcon from "assets/icons/commentsIcon";
import briefcaseIcon from "assets/icons/briefcase-icon.png";
import WorkticketIcon from "assets/icons/workticketIcon";
import { mapIcons } from "./mapIcons";
import moment from "moment";
import CommentDialog from "../commentDialog";
import httpService from "services/httpService";
import { apiUrl } from "lib/config";
import { Skeleton } from "@material-ui/lab";
import { addWorkticketComment } from "services/workticketService";
import { Alert } from "@material-ui/lab";
import { getUserType } from "./mapUtils";

const WorkticketCard = ({ id, onFlyTo, onView, onClose }) => {
  const [cardState, setCardState] = useState("normal");
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [workticketDetails, setWorkticketDetails] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  console.log("workticketDetails", workticketDetails);

  const handleOnFlyTo = () => {
    onFlyTo();
    setCardState("selected");
  };

  const fetchWorkticketDetails = async () => {
    try {
      const response = await httpService.get(
        `${apiUrl}/map/locations/worktickets/${id}`
      );
      setWorkticketDetails(response.data.data);
    } catch (error) {
      console.log("Error fetching workticket details:", error);
    }
  };

  const handleCommentSubmit = async (commentText) => {
    try {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      console.log("currentUser", currentUser);
      console.log("userType", getUserType(currentUser));

      await addWorkticketComment(id, {
        body: commentText,
        tab: getUserType(currentUser),
      });

      setCommentDialogOpen(false);
      setSnackbarMessage("Comment added successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error adding comment:", error);
      setSnackbarMessage("Failed to add comment. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      width: 328,
      boxShadow: "0 4px 20px rgba(89, 89, 89, 0.25)",
      borderRadius: 8,
    },
    workticketIcon: {
      width: 24,
      height: 24,
      marginRight: 4,
    },
    avatar: {
      marginRight: 4,
      width: 24,
      height: 24,
      border: "2px solid white",
    },
    workticketNumber: {
      fontSize: 12,
      fontWeight: 600,
      color: theme.palette.text.main,
    },
    stackedAvatar: {
      marginLeft: -12, // Negative margin to overlap avatars
    },
    workticketSubject: {
      fontSize: 14,
      fontWeight: 600,
      color: "#4D4D4D",
      lineHeight: "19px",
    },
    workticketDueDate: {
      height: 21,
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      justifyContent: "center",
      fontSize: 10,
      fontWeight: 600,
      color: theme.palette.primary.main,
      backgroundColor: "#F8F8F8",
      borderRadius: 4,
    },
    cardContent: {
      padding: "12px !important",
      display: "flex",
      flexDirection: "column",
      gap: 8,
    },
    closeButton: {
      padding: 0,
      height: 24,
      width: 24,
      minHeight: 0,
      minWidth: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    customerName: {
      fontSize: 12,
      fontWeight: 400,
    },
    locationHeader: {
      color: theme.palette.primary.main,
      fontSize: 14,
      fontWeight: 600,
    },
    imageContainer: {
      position: "relative",
      height: "100%",
    },
    tradesContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: 4,
      margin: "4px 0",
    },
    trade: {
      padding: "4px 8px",
      border: "1px solid #ECECEC",
      borderRadius: 50,
      fontSize: 12,
      lineHeight: 1.1,
    },
    actionButtons: {
      display: "flex",
      gap: 8,
      height: 32,
    },
    viewButton: {
      textTransform: "none",
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.inverted,
      flex: 1,
      "&:hover": {
        transition: "0.2s ease-in",
        opacity: 0.8,
        backgroundColor: theme.palette.secondary.main,
      },
    },
    zoomInButton: {
      textTransform: "none",
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      padding: "0 16px",
      minWidth: 0,
      width: "min-content",
      whiteSpace: "nowrap",
    },
    commentButton: {
      minWidth: 0,
      width: "min-content",
      padding: "0 8px",
      color: theme.palette.secondary.main,
      borderColor: "#D9D9D9",
    },
    bodyText: {
      fontSize: 12,
    },
    skeleton: {
      backgroundColor: "#F8F8F8",
      opacity: 0.7,
    },
    skeletonText: {
      backgroundColor: "#F8F8F8",
      borderRadius: 4,
      opacity: 0.7,
    },
    disabledButton: {
      opacity: 0.5,
      pointerEvents: "none",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (id) {
      fetchWorkticketDetails();
    }
  }, [id]);

  if (!workticketDetails) {
    return (
      <Box>
        <Card className={classes.root}>
          <CardContent className={classes.cardContent}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <Skeleton
                  variant="rect"
                  width={24}
                  height={24}
                  className={classes.skeleton}
                />
                {[1, 2].map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="circle"
                    width={24}
                    height={24}
                    className={`${classes.skeleton} ${
                      index > 0 ? classes.stackedAvatar : ""
                    }`}
                  />
                ))}
                <Skeleton
                  variant="text"
                  width={60}
                  className={classes.skeletonText}
                />
              </Box>
              <IconButton className={classes.closeButton} onClick={onClose}>
                <XMarkIcon width={20} height={20} color="#747474" />
              </IconButton>
            </Box>

            <Skeleton
              variant="text"
              width="80%"
              height={32}
              className={classes.skeletonText}
            />

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Skeleton
                variant="rect"
                width={100}
                height={24}
                className={classes.skeleton}
              />
              <Skeleton
                variant="rect"
                width={120}
                height={21}
                className={classes.skeleton}
              />
            </Box>

            <Box display="flex" alignItems="flex-start">
              <Skeleton
                variant="rect"
                width={18}
                height={18}
                className={classes.skeleton}
              />
              <Skeleton
                variant="text"
                width="60%"
                className={classes.skeletonText}
                style={{ marginLeft: 6 }}
              />
            </Box>

            <Box display="flex" alignItems="flex-start">
              <Skeleton
                variant="rect"
                width={18}
                height={18}
                className={classes.skeleton}
              />
              <Skeleton
                variant="text"
                width="80%"
                className={classes.skeletonText}
                style={{ marginLeft: 6 }}
              />
            </Box>

            <Box className={classes.tradesContainer}>
              <Skeleton
                variant="rect"
                width={120}
                height={26}
                className={classes.skeleton}
                style={{ borderRadius: 50 }}
              />
            </Box>

            <Box className={classes.actionButtons}>
              <Button
                variant="contained"
                className={`${classes.viewButton} ${classes.disabledButton}`}
                disableElevation
                disabled
              >
                View
              </Button>
              <Button
                variant="outlined"
                className={`${classes.zoomInButton} ${classes.disabledButton}`}
                disabled
              >
                Zoom In
              </Button>
              <Button
                variant="outlined"
                className={`${classes.commentButton} ${classes.disabledButton}`}
                disabled
              >
                <CommentsIcon strokeColor="#4F98BC" />
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }

  const {
    service_category,
    number,
    subject,
    due_date,
    users,
    workticket_type,
    status,
    type,
    badge_status,
    location,
  } = workticketDetails;

  return (
    <Box>
      <Card className={classes.root}>
        <CardContent className={classes.cardContent}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <img
                className={classes.workticketIcon}
                src={mapIcons[workticket_type][badge_status][0]}
                alt="workticket"
              />
              {users.slice(0, 2).map((user, index) => (
                <Avatar
                  key={user.name}
                  src={user.avatar}
                  alt={user.name}
                  className={`${classes.avatar} ${
                    index > 0 ? classes.stackedAvatar : ""
                  }`}
                />
              ))}
              <Typography className={classes.workticketNumber}>
                {number}
              </Typography>
            </Box>
            <IconButton className={classes.closeButton} onClick={onClose}>
              <XMarkIcon width={20} height={20} color="#747474" />
            </IconButton>
          </Box>
          <Typography className={classes.workticketSubject}>
            {subject}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={classes.statusContainer}
          >
            <WorkticketStatusChip
              status={status}
              due_date={due_date}
              type={type}
            />
            <Typography className={classes.workticketDueDate}>
              Due: {moment(due_date).format("MM/DD/YYYY")}
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-start">
            <img
              src={briefcaseIcon}
              alt="briefcase"
              style={{ marginRight: 6 }}
            />
            <Typography variant="body2" className={classes.bodyText}>
              <span style={{ fontWeight: 600 }}>{location?.name}</span>
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-start">
            <img src={mapIcon} alt="map" style={{ marginRight: 6 }} />
            <Typography variant="body2" className={classes.bodyText}>
              <span style={{ fontWeight: 600 }}>Address:</span>{" "}
              {location?.address}. {location?.city}, {location?.state}.
            </Typography>
          </Box>
          <Box className={classes.tradesContainer}>
            <Typography key={service_category} className={classes.trade}>
              {service_category}
            </Typography>
          </Box>
          <Box className={classes.actionButtons}>
            <Button
              variant="contained"
              onClick={onView}
              className={classes.viewButton}
              disableElevation
            >
              View
            </Button>
            <Button
              variant="outlined"
              onClick={handleOnFlyTo}
              className={classes.zoomInButton}
            >
              Zoom In
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setCommentDialogOpen(true);
              }}
              className={classes.commentButton}
            >
              <CommentsIcon strokeColor="#4F98BC" />
            </Button>
          </Box>
        </CardContent>
      </Card>
      <CommentDialog
        open={commentDialogOpen}
        onClose={() => setCommentDialogOpen(false)}
        title={workticketDetails?.number}
        subtitle="Add a comment to this Workticket"
        onSubmit={handleCommentSubmit}
        icon={
          <div style={{ marginBottom: "5px" }}>
            <WorkticketIcon height={30} width={30} />
          </div>
        }
      />
      <Portal>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Portal>
    </Box>
  );
};

export default WorkticketCard;
