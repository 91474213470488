import { Link } from "react-router-dom";
import PageHeader from "components/common/PageHeader/pageHeader";
import { Box, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useLocationDetailsContext } from "pages/locationDetails/context";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export const DesktopHeader = () => {
  const classes = useStyles();
  const {
    details: { data, isLoading },
  } = useLocationDetailsContext();

  return (
    <Box className={classes.root}>
      {!isLoading ? (
        <PageHeader pageTitle={data?.name ?? "NaME"}>
          <Link to="/dashboard">Location Dashboard</Link>
        </PageHeader>
      ) : (
        <Skeleton variant="text" width={200} height={24} />
      )}
    </Box>
  );
};
