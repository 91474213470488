import React from "react";
import Box from "@material-ui/core/Box";
import {
  actions,
  assignedTo,
  chartBar,
  clipboard,
  clockIn,
  currentLocation,
  customer,
  dates,
  document,
  financial,
  headquarter,
  insights,
  levelCompliance,
  location,
  methodCompletion,
  partners,
  ratings,
  reactiveWT,
  responsible,
  source,
  star,
  status,
  wtNumber,
  wtType,
} from "./images/images";
import useStyles from "./styles";

const iconsMap = {
  actions,
  assignedTo,
  clipboard,
  clockIn,
  currentLocation,
  customer,
  dates,
  document,
  financial,
  headquarter,
  insights,
  levelCompliance,
  location,
  methodCompletion,
  partners,
  ratings,
  reactiveWT,
  responsible,
  source,
  star,
  status,
  wtNumber,
  wtType,
  chartBar,
};

const BadgeIcon = ({
  iconType = "actions",
  color = "#000",
  backgroundColor = "#999",
  width = 16,
  height = 16,
}) => {
  const classes = useStyles();
  const renderIcon = iconsMap[iconType]
    ? iconsMap[iconType](color, width, height)
    : null;

  return (
    <Box
      className={classes.suggestedPromptBadge}
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      {renderIcon}
    </Box>
  );
};

export default BadgeIcon;
