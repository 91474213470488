import moment from "moment";
import {
  getJobsFilters,
  getJobsCustomer,
  getJobsZipFilters,
  getCities,
} from "services/jobService";
import {
  getCustomers,
  getZones,
  getJobTierUsersList,
  getCustomersLabor,
} from "services/userService";

import { getDeliveryTypes } from "services/filterService";

import { getServiceCategoriesFilters } from "services/workticketService";

import { getExternalFilters } from "services/workticketService";
import { getProjectsUser } from "services/projectService";
import { getIndustries } from "services/serviceService";
import { getSurveys } from "services/surveyFrontlineService";
import { getEvaluationsFilter } from "services/surveyEvaluationsService";
import { getNoticeTypes } from "services/noticeService";

const filterLinear = [
  "date_column",
  "start_date",
  "end_date",
  "all_jobs",
  "margin",
  "margin_symbol",
  "revenue",
  "revenue_symbol",
  "cost",
  "cost_symbol",
  "status_text",
  "is_workticket_paid",
  "is_reactive_accepted",
  "insight_report",
  "actively_completing",
  "contain_clock",
  "inspections_text",
  "last_inspections_text",
  "tab",
];

export const getFilterString = (
  filterData,
  returnValue = true,
  addBreakdownLB = false,
  filterDateData = true
) => {
  let queryString = "";
  if (filterData) {
    if (filterDateData && filterData.dateFilters) {
      if (filterData.dateFilters.startDateActive) {
        queryString += `filters[start_date]=${moment(
          filterData.dateFilters.startDate
        ).format("YYYY-MM-DD 00:00:00")}&`;
      }

      if (filterData.dateFilters.endDateActive) {
        queryString += `filters[end_date]=${moment(
          filterData.dateFilters.endDate
        ).format("YYYY-MM-DD 23:59:59")}&`;
      }
    }
    if (filterData.filters.length) {
      filterData.filters.forEach((element) => {
        element.values.forEach((item) => {
          const itemValue =
            typeof item.value == "number"
              ? item.value
              : encodeURIComponent(item.value.replace("&", "%26"));
          if (filterLinear.includes(element.filter)) {
            queryString += `filters[${element.filter}]=${itemValue}&`;
          } else {
            queryString += `filters[${element.filter}][]=${itemValue}&`;
          }
        });
      });
    }
  }
  if (addBreakdownLB && filterData?.breakdown) {
    queryString += `filters[lb_breakdown]=${filterData.breakdown}&`;
  }

  if (queryString === "" && returnValue) {
    queryString = "filters[]&";
  }
  return queryString;
};

export const getFilterJobs = async (showMain = null) => {
  const resultJob = await getJobsFilters(showMain);
  return resultJob.data.data.jobs.map((job) => {
    return {
      id: job.id,
      job_description: `${job.job_description}${
        Number(job?.job_type) === 2 ? " (Child)" : ""
      }`,
      job_number: job.job_number,
      site_number: job?.site_number,
      job_type: job?.job_type,
      parent_job_id: job?.parent_job_id,
    };
  });
};

export const getFilterJobsCustomer = async (customer, showMain = null) => {
  const resultJob = await getJobsCustomer(customer, showMain);
  return resultJob.data.data.jobs.map((job) => {
    return {
      id: job.id,
      job_description: job.job_description,
      job_number: job.job_number,
    };
  });
};

export const getFilterCustomers = async () => {
  const resultCustomers = await getCustomers();
  const { customers } = resultCustomers.data.data;
  return customers.map((customer) => {
    return { value: customer.id, label: customer.name };
  });
};

export const getFilterCustomersLabor = async () => {
  const resultCustomers = await getCustomersLabor();
  const { data: customers } = resultCustomers.data;
  return customers.map((customer) => {
    return { value: customer.id, label: customer.name };
  });
};

export const getFilterZones = async () => {
  const resultZones = await getZones();
  const { job_zones } = resultZones.data.data;
  const jobZones = [...new Set(job_zones)].sort();
  return jobZones.map((zone) => {
    return { value: zone, label: zone };
  });
};

export const getFilterCities = async () => {
  const resultCities = await getCities();
  const { cities } = resultCities.data.data;
  return cities.map((city) => {
    return { value: city.city, label: city.city };
  });
};

export const getFilterZips = async () => {
  const resultZips = await getJobsZipFilters();
  const { zips } = resultZips.data.data;
  return zips.map((zip) => {
    return { value: zip, label: zip.toString() };
  });
};

export const getFilterWorkticketExternal = async () => {
  const resultExternal = await getExternalFilters();
  const { categories, priorities, statuses } = resultExternal.data.data;
  const renderCategories = categories.map((category) => {
    return { value: category.name, label: category.name };
  });

  const renderPriorities = priorities.map((priority) => {
    return { value: priority.uid, label: priority.name };
  });

  const renderStatuses = statuses
    .filter((status) => status.uid_extended)
    .map((status) => {
      return {
        value: status.uid_extended,
        label: `${status.name} ${
          status.name_extended ? ` - ${status.name_extended}` : ""
        }`,
      };
    });

  return {
    categories: renderCategories,
    priorities: renderPriorities,
    statuses: renderStatuses,
  };
};

export const getFilterProjects = async () => {
  const resultProject = await getProjectsUser();
  const projects = resultProject.data.data.projects;
  return projects.map((project) => {
    return { value: project.id, label: project.title };
  });
};

export const getFilterSeniorDirectors = async () => {
  const resultDirectors = await getJobTierUsersList("senior_director");
  const { users } = resultDirectors.data.data;
  return users.map((user) => {
    return { value: user.id, label: `${user.first_name} ${user.last_name}` };
  });
};

export const getFilterDirectors = async () => {
  const resultDirectors = await getJobTierUsersList("director");
  const { users } = resultDirectors.data.data;
  return users.map((user) => {
    return {
      value: Number(user.id),
      label: `${user.first_name} ${user.last_name}`,
    };
  });
};

export const getFilterManagers = async () => {
  const resultManagers = await getJobTierUsersList("manager");
  const { users } = resultManagers.data.data;
  return users.map((user) => {
    return {
      value: Number(user.id),
      label: `${user.first_name} ${user.last_name}`,
    };
  });
};

export const getFilterServiceCategories = async () => {
  const resultServices = await getServiceCategoriesFilters();
  const services = resultServices.data.data.service_categories;
  return services.map((service) => {
    return { value: service.name, label: service.name };
  });
};

export const filtersDashboard = (interval, dateFilters, dashboardState) => {
  let payload = new FormData();
  const { dasboardPropsData } = dashboardState;

  payload.append("filters[interval]", interval);

  if (dateFilters) {
    if (dateFilters.startDateActive) {
      payload.append(
        "filters[start_date]",
        moment(dateFilters.startDate).format("YYYY-MM-DD 00:00:00")
      );
    }

    if (dateFilters.endDateActive) {
      payload.append(
        "filters[end_date]",
        moment(dateFilters.endDate).format("YYYY-MM-DD 23:59:59")
      );
    }
  }
  if (dasboardPropsData?.checked && dasboardPropsData.jobNumbersInBound) {
    dasboardPropsData.jobNumbersInBound.forEach((job_number) => {
      payload.append("filters[job_number][]", job_number);
    });
    if (dasboardPropsData.jobNumbersInBound.length === 0) {
      payload.append("filters[job_number][]", -1);
    }
  } else if (dasboardPropsData.selectedLocation) {
    payload.append(
      "filters[job_number][]",
      dasboardPropsData.selectedLocation.job_number
    );
  }
  return payload;
};

export const filtersDashboardJobs = (filterData, dashboardState) => {
  let payload = new FormData();
  const { dasboardPropsData } = dashboardState;

  if (filterData) {
    if (filterData.filters.length) {
      filterData.filters.forEach((element) => {
        element.values.forEach((item) => {
          payload.append(`filters[${element.filter}][]`, item.value);
        });
      });
    }
  }

  if (dasboardPropsData?.checked && dasboardPropsData.jobIdsInBound) {
    dasboardPropsData.jobIdsInBound.forEach((job_id) => {
      payload.append("filters[job][]", job_id);
    });
    if (dasboardPropsData.jobIdsInBound.length === 0) {
      payload.append("filters[job][]", -1);
    }
  } else if (dasboardPropsData.selectedLocation) {
    payload.append("filters[job][]", dasboardPropsData.selectedLocation.id);
  }

  return payload;
};

export const filtersDashboardKpiGeo = (
  interval,
  dateFilters,
  dashboardState
) => {
  let payload = new FormData();
  const { dasboardPropsData } = dashboardState;

  payload.append("filters[interval]", interval);

  if (dateFilters) {
    if (dateFilters.startDateActive) {
      payload.append(
        "filters[start_date]",
        moment(dateFilters.startDate).format("YYYY-MM-DD 00:00:00")
      );
    }

    if (dateFilters.endDateActive) {
      payload.append(
        "filters[end_date]",
        moment(dateFilters.endDate).format("YYYY-MM-DD 23:59:59")
      );
    }
  }

  if (dasboardPropsData?.checked && dasboardPropsData.currentMapBounds) {
    payload.append(
      "filters[geo1]",
      `${dasboardPropsData.currentMapBounds.sw.lng},${dasboardPropsData.currentMapBounds.sw.lat}`
    );
    payload.append(
      "filters[geo2]",
      `${dasboardPropsData.currentMapBounds.ne.lng},${dasboardPropsData.currentMapBounds.ne.lat}`
    );
  } else if (dasboardPropsData.selectedLocation) {
    payload.append("filters[job][]", dasboardPropsData.selectedLocation.id);
  }

  return payload;
};

export const filtersDashboardGeo = (filterData, dashboardState) => {
  let payload = new FormData();
  const { dasboardPropsData } = dashboardState;

  if (filterData) {
    if (filterData.filters.length) {
      filterData.filters.forEach((element) => {
        element.values.forEach((item) => {
          payload.append(`filters[${element.filter}][]`, item.value);
        });
      });
    }
    if (filterData.dateFilters) {
      if (filterData.dateFilters.startDateActive) {
        payload.append(
          "filters[start_date]",
          moment(filterData.dateFilters.startDate).format("YYYY-MM-DD 00:00:00")
        );
      }

      if (filterData.dateFilters.endDateActive) {
        payload.append(
          "filters[end_date]",
          moment(filterData.dateFilters.endDate).format("YYYY-MM-DD 23:59:59")
        );
      }
    }
  }

  if (dasboardPropsData?.checked && dasboardPropsData.currentMapBounds) {
    payload.append(
      "filters[geo1]",
      `${dasboardPropsData.currentMapBounds.sw.lng},${dasboardPropsData.currentMapBounds.sw.lat}`
    );
    payload.append(
      "filters[geo2]",
      `${dasboardPropsData.currentMapBounds.ne.lng},${dasboardPropsData.currentMapBounds.ne.lat}`
    );
  } else if (dasboardPropsData.selectedLocation) {
    payload.append("filters[job][]", dasboardPropsData.selectedLocation.id);
  }

  return payload;
};

export const getFilterIndustries = async () => {
  const resultIndustries = await getIndustries();
  return resultIndustries.data.data.industries.map((item) => {
    return { value: item.industry_id, label: item.name };
  });
};

export const getFilterIndustriesCustomer = async () => {
  const resultIndustries = await getIndustries();
  return resultIndustries.data.data.industries.map((item) => {
    return { value: item.id, label: item.name };
  });
};

export const getFilterDeliveryTypesCustomer = async () => {
  const resultDelivery = await getDeliveryTypes();
  return resultDelivery.data.map((item) => {
    return { value: item.id, label: item.name };
  });
};

export const getFilterSurveys = async () => {
  const resultSurveys = await getSurveys();
  return resultSurveys.data.data.surveys.map((survey) => {
    return {
      value: survey.id,
      label: `${survey.number} - ${survey.name}`,
    };
  });
};

export const getFilterEvaluations = async () => {
  const resultSurveys = await getEvaluationsFilter();
  return resultSurveys.data.data.surveys.map((survey) => {
    return {
      value: survey.id,
      label: `${survey.number} - ${survey.name}`,
    };
  });
};

export const getFilterNotices = async () => {
  const resultSurveys = await getNoticeTypes();
  return resultSurveys.data.data.notice_types.map((notice) => {
    return {
      value: notice.id,
      label: notice.name,
    };
  });
};

export const convertUriFilterData = (query, filter) => {
  let filterReturn = [];
  filter.forEach((filterElement) => {
    const iterator = query.entries();
    let element = iterator.next();

    while (!element.done) {
      if (
        element.value[0].includes(`[${filterElement}]`) &&
        !filterReturn.find((fItem) => fItem.filter === filterElement)
      ) {
        const queryArray = query.getAll(element.value[0]);
        filterReturn = [
          ...filterReturn,
          { filter: filterElement, values: queryArray },
        ];
      }
      element = iterator.next();
    }
  });
  return filterReturn;
};

export const convertUriFilterDateData = (query) => {
  let filterReturn = null;
  const startDate = query.get("filters[start_date]");
  const endDate = query.get("filters[end_date]");

  if (startDate && endDate) {
    filterReturn = {
      startDate,
      endDate,
    };
  }

  return filterReturn;
};

export const generatePartnerFilterString = (
  filterData,
  returnValue = true,
  addBreakdownLB = false,
  filterDateData = true
) => {
  let queryString = "";
  const filterLinear = [
    "rating",
    "insurance_compliance",
    "nda_signed",
    "minority_owned",
    "woman_owned",
    "veteran_owned",
    "disability_owned",
  ];

  if (filterData) {
    if (filterData.filters && filterData.filters.length) {
      filterData.filters.forEach((element) => {
        element.values.forEach((item) => {
          let itemValue =
            typeof item === "number" ? item : item.replace("&", "%26");

          if (filterLinear.includes(element.filter)) {
            queryString += `filters[${element.filter}]=${itemValue}&`;
          } else {
            queryString += `filters[${element.filter}][]=${itemValue}&`;
          }
        });
      });
    }
  }

  if (addBreakdownLB && filterData?.breakdown) {
    queryString += `filters[lb_breakdown]=${filterData.breakdown}&`;
  }

  if (queryString === "" && returnValue) {
    queryString = "filters[]&";
  }
  return queryString;
};
