import { Box, makeStyles, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import ChartHeaderDetails from "pages/dashboardKpiPage/components/chartHeaderDetails";
import ChartBarHorizontal from "components/common/Charts/ChartBarHorizontal";
import { useLocationDetailsContext } from "pages/locationDetails/context";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: 16,
    borderRadius: 4,
    boxShadow: "0px 2px 20px 0px rgba(173, 173, 173, 0.16)",
  },
}));

export const BarChart = () => {
  const classes = useStyles();
  const {
    metrics: {
      isLoading,
      data: { metrics, metaData },
    },
  } = useLocationDetailsContext();

  const formattedDate = (date) =>
    new Date(date).toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
    });

  const data = [
    {
      name: `${formattedDate(metaData?.start_date)} - ${formattedDate(
        metaData?.end_date
      )}`,
      Completed: metrics?.completed,
      "On Time": metrics?.ontime,
      "On Site": metrics?.ontime_onsite,
      "Fully Compliant": metrics?.fully_compliant,
    },
  ];

  const dataKeys = Object.keys(data[0]).filter((key) => key !== "name");

  const chartColors = {
    completed: "#9CBB65",
    onTime: "#BAE7FA",
    onSite: "#A67EC8",
    fullyCompliant: "#6973C6",
  };

  const chartHeaderData = [
    {
      label: "Completed",
      color: chartColors.completed,
      value: metrics?.completed,
      type: "number",
    },
    {
      label: "On Time",
      color: chartColors.onTime,
      value: metrics?.ontime,
      type: "number",
    },
    {
      label: "On Site",
      color: chartColors.onSite,
      value: metrics?.ontime_onsite,
      type: "number",
    },
    {
      label: "Fully Compliant",
      color: chartColors.fullyCompliant,
      value: metrics?.fully_compliant,
      type: "number",
    },
  ];

  return (
    <Box className={classes.root}>
      <Typography className={classes.chartLabel}>WT Compliance</Typography>
      {!isLoading && (
        <>
          <ChartHeaderDetails data={chartHeaderData} />
          <ChartBarHorizontal
            data={data}
            colors={Object.values(chartColors)}
            dataKeys={dataKeys}
            height={250}
          />
        </>
      )}
      {isLoading && <Skeleton height={250} width={425} />}
    </Box>
  );
};
